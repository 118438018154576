export const SIGNUP = 'session/SIGNUP';
export const SIGNUP_COMPLETED = 'session/SIGNUP_COMPLETED';
export const SIGNUP_FAILED = 'session/SIGNUP_FAILED';
export const LOGIN = 'session/LOGIN';
export const LOGIN_COMPLETED = 'session/LOGIN_COMPLETED';
export const LOGIN_FAILED = 'session/LOGIN_FAILED';
export const REFRESH = 'session/REFRESH';
export const REFRESH_COMPLETED = 'session/REFRESH_COMPLETED';
export const REFRESH_FAILED = 'session/REFRESH_FAILED';
export const GET_USER = 'session/GET_USER';
export const GET_USER_COMPLETED = 'session/GET_USER_COMPLETED';
export const GET_USER_FAILED = 'session/GET_USER_FAILED';
export const AUTHORIZE_USER = 'session/AUTHORIZE_USER';
export const AUTHORIZE_USER_COMPLETED = 'session/AUTHORIZE_USER_COMPLETED';
export const AUTHORIZE_USER_FAILED = 'session/AUTHORIZE_USER_FAILED';
export const RESET_AUTHORIZE = 'session/RESET_AUTHORIZE';
export const LOGOUT = 'session/LOGOUT';
export const INITIALIZE = 'session/INITIALIZE_SESSION';
export const SET_REDIRECT_AFTER_LOGIN = 'session/SET_REDIRECT_AFTER_LOGIN';
export const ADD_AUTH_REQUEST = 'session/ADD_AUTH_REQUEST';
