export const GET_TRANSACTIONS = 'transactions/GET_TRANSACTIONS';
export const GET_TRANSACTIONS_COMPLETED = 'transactions/GET_TRANSACTIONS_COMPLETED';
export const GET_TRANSACTIONS_FAILED = 'transactions/GET_TRANSACTIONS_FAILED';
export const GET_TRANSACTION = 'transactions/GET_TRANSACTION';
export const GET_TRANSACTION_COMPLETED = 'transactions/GET_TRANSACTION_COMPLETED';
export const GET_TRANSACTION_FAILED = 'transactions/GET_TRANSACTION_FAILED';
export const POST_TRANSACTION = 'transactions/POST_TRANSACTION';
export const POST_TRANSACTION_COMPLETED = 'transactions/POST_TRANSACTION_COMPLETED';
export const POST_TRANSACTION_FAILED = 'transactions/POST_TRANSACTION_FAILED';
export const POST_TRANSACTION_RESET_STATUS = 'transactions/POST_TRANSACTION_RESET_STATUS';
export const POST_TRANSACTION_FILE = 'transactions/POST_TRANSACTION_FILE';
export const POST_TRANSACTION_FILE_COMPLETED = 'transactions/POST_TRANSACTION_FILE_COMPLETED';
export const POST_TRANSACTION_FILE_FAILED = 'transactions/POST_TRANSACTION_FILE_FAILED';
export const DELETE_TRANSACTION = 'transactions/DELETE_TRANSACTION';
export const DELETE_TRANSACTION_COMPLETED = 'transactions/DELETE_TRANSACTION_COMPLETED';
export const DELETE_TRANSACTION_FAILED = 'transactions/DELETE_TRANSACTION_FAILED';
